.section-testimonial-style2 {
  blockquote {
    padding: 60px;
    display: flex;
    margin-bottom: 40px;
    border-radius: var(--#{$prefix}border-radius);
    background-color: var(--#{$prefix}primary-bg-subtle);

    .quote_icon {
      flex: 0 0 50px;
      margin: 0 30px 0 0;
    }

    span {
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
      margin-bottom: 20px;
      color: var(--#{$prefix}dark);
      font-family: var(--#{$prefix}font-heading);
    }

    cite {
      font-size: 18px;
      line-height: 28px;
      color: var(--#{$prefix}body-color);
    }
  }
}
