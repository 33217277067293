.toolbarClassName {
  border-radius: 10px;
}
.wrapperClassName {
  border: 1px solid rgb(231, 221, 221);
  padding: 1rem;
}
.editorClassName {
  background-color: rgb(239, 222, 222);
  border-radius: 10px;
  padding: 1rem;
}

.wrapper-class1 {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-class1 {
  background-color: lightgray;
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbar-class1 {
  border: 1px solid #ccc;
}
