//----------------------------------------------------------//
//	POSITION
//----------------------------------------------------------//

.section-used-for-style1 {
  //   .work_process_item {
  //     padding: 40px;

  //     transition: var(--pw-transition);
  //     box-shadow: var(--pw-box-shadow);
  //     background-color: var(--pw-white);
  //     border-radius: var(--pw-border-radius);

  //     // margin-bottom: 2rem;

  //     &:hover {
  //       -webkit-transform: translateY(-4px);
  //       transform: translateY(-4px);
  //       -webkit-box-shadow: 0px 8px 30px 0px rgba(43, 171, 160, 0.07);
  //       box-shadow: 0px 8px 30px 0px rgba(43, 171, 160, 0.07);
  //     }

  //     .item_title {
  //       font-size: 22px;
  //       line-height: 28px;
  //       margin: 25px 0 15px;
  //     }
  //   }

  .card {
    &:hover {
      transform: translateY(-4px);
      box-shadow: 0px 8px 30px 0px rgba(43, 171, 160, 0.07);
    }
  }

  .item {
    background-color: unset !important;
    margin: 0;
    margin-bottom: 20px;

    .step {
      font-weight: 600;
      letter-spacing: 0.08rem;
    }
  }

  //   .content {
  //     .title {
  //       margin-bottom: 7px;
  //       position: relative;
  //       z-index: 1;
  //     }
  //   }

  .number {
    position: absolute;
    font-size: 100px;
    line-height: 88px;
    color: $primary;
    opacity: 0.06;
    font-weight: 700;
    top: 24px;
    left: 24px;
    z-index: 1;
  }

  .wrap-content {
    // padding-right: 14px;
    // max-width: 520px;
    // position: sticky;
    // top: 100px;

    // position: fixed;
    // width: 445px;
    // left: 40px;
    // top: 100px;
  }

  @media (min-width: 992px) {
    .position-lg-absolute {
      position: absolute !important;
    }
    .position-lg-sticky {
      position: sticky;
    }
  }
}
