.slot-box {
  border: 2px solid #e2e8f0;
  margin: 0 0 30px;
  padding: 15px 15px 0;
  border-radius: 8px;
}
.slot-box .slot-header {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  border-bottom: 1px solid #e2e8f0;
  margin-bottom: 15px;
}
.slot-box .slot-header ul li {
  font-size: 14px;
  font-weight: 500;
  margin: 0 15px 15px 0;
  display: inline-block;
}
.slot-box .slot-header ul li:last-child {
  margin-right: 0;
}
