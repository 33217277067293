h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 800;
  color: var(--#{$prefix}dark);
  font-family: var(--#{$prefix}font-heading);
}

hr {
  opacity: 1;
  height: 1px;
  border: none;
  margin: 25px 0;
  background-color: var(--#{$prefix}border-color);
}

mark {
  padding: 0;
  color: var(--#{$prefix}primary);
  background-color: transparent;
}

// h1 a,
// h2 a,
// h3 a,
// h4 a,
// h5 a,
// h6 a {
//   color: inherit;
// }

// h1 {
//   font-size: 50px;
// }

// h2 {
//   font-size: 40px;

//   @media #{$xs} {
//     font-size: 31px;
//   }
// }
// h3 {
//   font-size: 30px;
// }
// h4 {
//   font-size: 25px;
// }
// h5 {
//   font-size: 20px;
// }
// h6 {
//   font-size: 16px;
// }

a {
  outline: 0;
  display: inline-block;
  // display: block;
  text-decoration: none;

  &:hover {
    color: $primary;
    @include transition(0.3s);
  }

  &:active,
  &:focus,
  &:hover,
  &:visited {
    outline: 0;
    text-decoration: none;
  }
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

p {
  //   margin: 0px;
  //   color: $text-color;

  //   font-size: 16px;
  //   font-weight: 400;
  //   line-height: 26px;

  //   @media #{$lg} {
  //     font-size: 16px;
  //     line-height: 26px;
  //   }
  //   @media #{$xs} {
  //     font-size: 16px;
  //     line-height: 26px;
  //   }

  //   a {
  //     position: relative;

  //     &:after {
  //       background: none repeat scroll 0 0 transparent;
  //       bottom: 0;
  //       content: "";
  //       display: block;
  //       height: 1px;
  //       left: 50%;
  //       position: absolute;
  //       background: $theme-color;
  //       transition: width 0.3s ease 0s, left 0.3s ease 0s;
  //       width: 0;
  //     }
  //     &:hover:after {
  //       width: 100%;
  //       left: 0;
  //     }
  //   }
}

[class*="unordered_list"] {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  > li {
    // float: left;
    display: inline-block;
    list-style: none;
  }
}

.unordered_list_block {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;

  > li {
    width: 100%;
    display: block;
  }
}

@for $i from 1 to 8 {
  .line-clamp-#{$i} {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $i; /* number of lines to show */
    line-clamp: 1;
  }
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.single-posts-wrapper {
  .blog-content {
    blockquote,
    figure {
      margin: 0 0 1rem;
    }
    .figure {
      display: inline-block;
      img {
        border-radius: var(--pw-border-radius-xl) !important;
      }
    }
    .table-caption,
    figcaption {
      padding: 0;
      // margin: 10px 0 0; // mb from figure-img, spacer

      color: var(--pw-secondary-color);
      text-align: center;
      font-style: italic;
      font-size: 0.85rem;
    }

    blockquote {
      display: block;
      margin: 0 0;
      padding: 0 0;
      border-radius: initial;
      background-color: transparent;

      border-left: 3px solid var(--pw-primary);
      margin: 35px !important;
      padding-left: 20px;
      // color: #687a86;
      font-style: italic;
      cite {
        color: var(--pw-secondary-color);
      }
    }
    ul,
    ol {
      margin-left: 0;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      padding-inline-start: 1.8rem;
      line-height: 2rem;
    }

    /* NOTE : добавлена поддержка многоуровневых маркерованных списков*/
    ul {
      > li {
        // display: block;
        // position: relative;
        margin: 0 0 5px 1.7em;

        > ul {
          margin: 5px 0 0 0;
          > li {
            margin-left: 1.8em;

            > ul {
              > li {
                margin-left: 2.5em;
              }
            }
          }
        }
      }
    }

    /* NOTE : добавлена поддержка многоуровневых нумерованных списков*/
    ol {
      list-style-type: decimal;
      counter-reset: list1;
      > li {
        display: block;
        position: relative;
        margin: 0 0 5px 1.7em;
        &::before {
          position: absolute;
          left: -1.7em;
          counter-increment: list1;
          content: counter(list1) ".";
          font-weight: 600;
        }
        > ol {
          counter-reset: list2;
          margin: 5px 0 0 0;
          > li {
            margin-left: 1.8em;
            &::before {
              left: -1.8em;
              counter-increment: list2;
              content: counter(list1) "." counter(list2) ".";
            }
            > ol {
              counter-reset: list3;
              > li {
                margin-left: 2.5em;
                &::before {
                  left: -2.5em;
                  counter-increment: list3;
                  content: counter(list1) "." counter(list2) "." counter(list3)
                    ".";
                }
              }
            }
          }
        }
      }
    }
  }
}
