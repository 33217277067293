.hero_section {
  .heading_text {
    font-size: 40px;
    line-height: 44px;
    margin-bottom: 10px;
  }
  .hero_section_counter {
    margin-top: 20px;
  }
}
.hero_image_wrap {
  padding: 30px;
}
.hero_section_counter {
  .counter_value {
    font-size: 30px;
    line-height: 24px;
  }
  .counter_description {
    font-size: 16px;
    max-width: 130px;
    line-height: 22px;
  }
  margin: -15px;
  > li {
    padding: 15px;
  }
}
.section_heading {
  .section_heading_text {
    font-size: 30px;
    line-height: 38px;
  }
}
.decoration_wrapper {
  z-index: 1;
  position: relative;
  .decoration_item {
    z-index: -1;
    position: absolute;
  }
}
.hero_section {
  padding: 160px 0 160px;
  .heading_text {
    font-size: 54px;
    font-weight: 800;
    line-height: 60px;
    margin-bottom: 20px;
  }
  p {
    max-width: 520px;
    margin-bottom: 42px;
    font-size: var(--pw-body-font-size-md);
    line-height: var(--pw-body-line-height-md);
  }
  .hero_section_counter {
    margin-top: 86px;
  }
  .shape_blur_shadow {
    top: 30px;
    left: -200px;
    width: 640px;
    height: 640px;
    opacity: 0.06;
    -webkit-filter: blur(67px);
    filter: blur(67px);
    background-color: #325d88;
    border-radius: var(--pw-border-radius-pill);
  }
  .shape_leaf {
    top: 160px;
    left: -50px;
  }
}
.hero_image_wrap {
  z-index: 1;
  padding: 60px;
  position: relative;
  &:after {
    width: 85%;
    height: 85%;
    z-index: -1;
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #c1e3de;
  }
  &:before {
    width: 85%;
    height: 85%;
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fef3de;
  }

  img {
    border-radius: 2rem;
  }
}
