/*===========================
    11. Blog CSS
===========================*/

@import "../variables";
@import "../utilities/mixin";

// @use "../layout/common";

.top-posts-section,
.blog-section-old {
  .single-blog-old {
    border-radius: 10px;
    background: $white;
    border: 1px solid $border-color;
    @include transition(0.3s);

    &:hover {
      @include box-shadow(0px 0px 47px rgba(161, 192, 255, 0.16));
    }

    .blog-img {
      overflow: hidden;
      border-radius: 10px 10px 0 0;
      position: relative;
      padding-bottom: 40px;

      a {
        display: block;
        overflow: hidden;
      }
      img {
        width: 100%;
        @include transition(0.3s);
      }

      .date-meta {
        position: absolute;
        bottom: 20px;
        left: 30px;
        border: 1px solid $border-color;
        padding: 8px 16px;
        border-radius: 30px;
        background: $white;
        display: inline-block;
        z-index: 2;
      }
    }

    &:hover {
      .blog-img {
        img {
          @include transform(scale(1.3) rotate(-7deg));
        }

        &.blog-img-2 {
          img {
            @include transform(scale(1) rotate(0));
          }
        }
      }
    }

    .blog-content {
      padding: 0 20px 35px 30px;

      @media #{$lg} {
        padding: 0 20px 35px;
      }
      @media #{$md} {
        padding: 0 20px 35px;
      }
      @media #{$xs} {
        padding: 0 20px 35px;
      }

      h4 {
        margin-bottom: 25px;
        font-weight: 600;

        @media #{$lg} {
          font-size: 25px;
        }
        @media #{$xs} {
          font-size: 25px;
        }
      }

      p {
        margin-bottom: 30px;

        &.quote {
          font-weight: 500;
          padding: 20px 30px;
          background: $gray;
          color: $heading-color;
          border-radius: 0 5px 5px 0;
          border-left: 5px solid $theme-color;
        }
      }

      .read-more-btn {
        display: inline-block;
        font-size: 18px;
        color: $body-color;
        border-radius: 50px;
        @include transition(0.3s);

        i {
          padding-left: 7px;
        }

        &:hover {
          color: $theme-color;
        }
      }
    }
  }

  .blog-style-2 {
    padding: 0;
    border: none;

    &:hover {
      @include box-shadow(none);
    }

    .blog-img {
      padding-bottom: 0px;
      margin-bottom: 30px;
      border-radius: 10px;

      img {
        border-radius: 10px;
      }
    }

    .blog-content {
      padding: 0;

      h4 {
        margin-bottom: 18px;
      }

      p {
        margin-bottom: 20px;
      }

      .blog-meta {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .read-more-btn {
          display: flex;
          align-items: center;
          margin-right: 10px;

          @media #{$xs} {
            font-size: 16px;
          }

          @media #{$sm} {
            font-size: 18px;
          }

          i {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background: $gray;
            padding-right: 5px;
            margin-left: 10px;
            font-size: 15px;
          }
        }

        .comment {
          font-size: 18px;
          display: flex;
          justify-content: center;
          align-items: center;

          @media #{$xs} {
            font-size: 16px;
          }

          @media #{$sm} {
            font-size: 18px;
          }

          i {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.left-side-wrapper {
  margin-right: 40px;

  @media #{$laptop} {
    margin-right: 30px;
  }

  @media #{$md} {
    margin-right: 0px;
  }

  @media #{$xs} {
    margin-right: 0px;
  }
}

.sidebar-wrapper {
  .sidebar-box {
    padding: 2rem;
    border: 1px solid $border-color;
    border-radius: 15px;

    background: $white;

    @include transition(0.3s);

    @media #{$xs} {
      // padding: 2.0rem 1.25rem;
    }
    @media #{$sm} {
      padding: 1.25rem 1rem;
    }

    &:hover {
      @include box-shadow(0px 0px 30px rgba(#d7e0fc, 0.45));
    }

    &.search-form-box {
      padding: 20px 25px;

      .search-form {
        width: 100%;
        position: relative;
        margin: 0;

        input {
          @include transition(0.3s);

          &:focus {
            border-color: $theme-color;
          }
        }
      }
    }

    &.recent-blog-box {
      .recent-blog-items {
        .recent-blog {
          display: flex;

          .recent-blog-img {
            border-radius: 10px;
            width: 100%;
            max-width: 100px;
            height: 100px;

            @media #{$laptop} {
              max-width: 90px;
              height: 90px;
            }
            @media #{$lg} {
              max-width: 90px;
              height: 90px;
            }
            @media #{$xs} {
              max-width: 80px;
              height: 80px;
            }
            @media #{$sm} {
              max-width: 90px;
              height: 90px;
            }

            img {
              width: 100%;
            }
          }

          .recent-blog-content {
            margin-left: 20px;

            h5 {
              font-weight: 500;
              margin-bottom: 8px;

              @media #{$laptop} {
                font-size: 18px;
              }
              @media #{$lg} {
                font-size: 18px;
              }
              @media #{$xs} {
                font-size: 18px;
              }
              @media #{$sm} {
                font-size: 20px;
              }
            }
          }
        }
      }
    }

    &.catagories-box {
      ul {
        li {
          margin-bottom: 20px;
          padding-left: 25px;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            @include transform(translateY(-50%));
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background: $body-color;
            @include transition(0.3s);
          }
          &:hover {
            &::before {
              background: $theme-color;
            }

            a {
              color: $theme-color;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }

          a {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            font-size: 18px;
            color: $body-color;
          }
        }
      }
    }

    // &.tags-box {
    //   ul {
    //     display: flex;
    //     flex-wrap: wrap;
    //     margin-right: -20px;

    //     li {
    //       margin-right: 20px;

    //       @media #{$xs} {
    //         margin-right: 15px;
    //       }

    //       &:last-child {
    //         margin-right: 0px;
    //       }

    //       a {
    //         font-size: 18px;
    //         padding: 12px 23px;
    //         border-radius: 30px;
    //         background: $gray;
    //         margin-bottom: 15px;
    //         color: $body-color;

    //         @media #{$laptop} {
    //           font-size: 16px;
    //           padding: 12px 18px;
    //         }
    //         @media #{$lg} {
    //           font-size: 16px;
    //         }
    //         @media #{$xs} {
    //           font-size: 16px;
    //           padding: 12px 18px;
    //         }
    //         @media #{$sm} {
    //           font-size: 18px;
    //         }

    //         &:hover {
    //           background: $theme-bg;
    //           color: $white;
    //         }
    //       }
    //     }
    //   }
    // }

    h4 {
      margin-bottom: 30px;
    }
  }
}

// $color_1: var(--pw-primary);
// $color_2: var(--pw-white);
// $color_3: var(--pw-dark);
$background-color_1: var(--#{$prefix}white);
$background-color_2: var(--#{$prefix}primary);

// a {
//   outline: 0;
//   display: inline-block;
//   text-decoration: none;
//   font-family: var(--#{$prefix}font-heading);
//   transition: var(--#{$prefix}transition);
// }

.tag-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  justify-content: space-between;

  row-gap: 20px;
  margin: 50px 0;
  padding: 50px 0;

  border-top: 1px #e0e5eb;

  padding-bottom: 0;
  margin-bottom: 0;

  .tags {
    display: flex;
    align-items: center;

    .tag {
      // color: var(--ed-color-heading-primary);
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0;
    }
    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      list-style: none;
      margin-left: 20px;

      column-gap: 10px;

      row-gap: 15px;
      li {
        a {
          color: var(--#{$prefix}body-color);
          border: 1px solid var(--#{$prefix}border-color);

          // color: var(--ed-color-text-body);
          // background-color: var(--ed-color-grey-1);
          font-size: 16px;
          font-weight: 400;

          padding: 8px 20px;
          border-radius: 100px;
          &:hover {
            // color: var(--ed-color-common-white);
            // background-color: var(--ed-color-theme-primary);

            color: var(--#{$prefix}white);
            border-color: var(--#{$prefix}primary);
            background-color: var(--#{$prefix}primary);
          }
        }
      }
    }
  }
  .social-list-wrap {
    display: flex;
    align-items: center;

    column-gap: 20px;
    .share {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0;
    }
    .social-list {
      background-color: $gray-300;
      padding: 5px 20px;
      list-style: none;
      border-radius: 100px;
      li {
        display: inline-flex;
        &:not(:last-of-type) {
          margin-right: 15px;
        }
        // a {
        //   font-size: 16px;
        //   color: var(--ed-color-heading-primary);
        //   border-radius: 50%;
        //   &:hover {
        //     color: var(--ed-color-theme-primary);
        //   }
        // }
      }
    }
  }
}

.post-category {
  margin: -3px;
  > li {
    margin: 3px;
  }
}

.post-category-list {
  > li:not(:last-child) {
    margin-bottom: 16px;
  }

  a {
    display: flex;

    align-items: center;
    color: var(--#{$prefix}body-color);
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    &:hover {
      color: var(--#{$prefix}secondary);
    }
  }
}

.post-meta-wrapper {
  margin: 1.25rem 0;
}

.post-meta {
  padding: 0 0;
  margin: 0 0;

  .unordered-list {
    margin: 0;
    padding: 0;
    padding-inline-start: 0;
  }

  > li {
    display: inline-block;
    font-size: 16px;
    line-height: 26px;
    position: relative;

    &:not(:last-child) {
      margin-right: 15px;
      padding-right: 14px;

      &:before {
        top: 50%;
        right: 0;
        width: 1px;
        content: "";
        height: 22px;
        position: absolute;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        background-color: var(--#{$prefix}border-color);
      }
    }

    i {
      color: $primary;
      padding-right: ($spacer * 0.25);
    }

    > a {
      display: block;
      color: $body-color;

      &:hover {
        color: $primary;
      }
    }
  }
}

.post-tags {
  margin: -5px;

  > li {
    margin: 5px;
  }

  a {
    display: block;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    padding: 11px 20px;
    border-radius: 40px;
    color: var(--#{$prefix}body-color);
    border: 1px solid var(--#{$prefix}border-color);

    &:hover {
      color: var(--#{$prefix}white);
      border-color: var(--#{$prefix}primary);
      background-color: var(--#{$prefix}primary);
    }
  }
}

.blog-item {
  // overflow: hidden;
  // position: relative;

  // background-color: $white;
  // border-radius: $border-radius;

  // box-shadow: var(--#{$prefix}box-shadow);
  // transition: var(--#{$prefix}transition);

  &:not(:last-child) {
    margin-bottom: 60px;
  }
  // &:hover {
  //   transform: translateY(-6px);
  //   box-shadow: var(--#{$prefix}box-shadow-lg);
  // }

  .blog-image-wrap {
    display: block;
    overflow: hidden;
    position: relative;

    img {
      border-top-left-radius: var(--#{$prefix}border-radius-xxl);
      border-top-right-radius: var(--#{$prefix}border-radius-xxl);

      // min-height: 225px;
      height: 250px;
      object-fit: cover;
    }

    //   img {
    //     width: 100%;
    //     display: block;
    //     // transition: var(--#{$prefix}transition);

    //     // max-height: 230px;
    //     object-fit: contain;
    //   }
    //   &:hover {
    //     // img {
    //     //   -webkit-transform: scale(1.08);
    //     //   transform: scale(1.08);
    //     // }
    //   }
  }
  .blog-content {
    z-index: 1;
    position: relative;
    padding: 0 28px 30px 38px;
  }
  .post-category {
    left: 30px;
    top: -200px;
    position: absolute;
    a {
      //   color: $primary;
      //   background-color: $background-color_1;

      //   display: block;
      //   font-size: 14px;
      //   font-weight: 600;
      //   line-height: 18px;
      //   padding: 11px 20px;
      border-radius: 40px;
      //   //     color: var(--pw-white);
      //   //     background-color: var(--pw-secondary);

      //   &:hover {
      //     color: $white;
      //     background-color: $background-color_2;
      //   }
    }
  }
  .item-title {
    font-size: 24px;
    line-height: 30px;
    margin: 16px 0 15px;

    // a {
    //   color: $dark;
    //   &:hover {
    //     color: $primary;
    //   }
    // }
  }

  // p {
  //   margin-bottom: 23px;
  // }
}

.blog-item-small {
  display: flex;
  .blog-image {
    flex: 0 0 90px;
    max-width: 90px;
    margin-right: 20px;
  }
  .blog-_image-wrap {
    display: block;
    overflow: hidden;
    border-radius: var(--#{$prefix}border-radius);
    img {
      transition: var(--#{$prefix}transition);
    }
  }
  .item-title {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;
    a {
      color: $dark;
      &:hover {
        color: $primary;
      }
    }
  }
  .post-meta {
    margin: 0 0;

    > li {
      font-size: 14px;
      line-height: 24px;
      &:not(:last-child) {
        margin-right: 11px;
        padding-right: 10px;
      }
    }
  }
  &:hover {
    .blog-image-wrap {
      img {
        // transform: scale(1.08);
      }
    }
  }
}

[class*="_details_section"] {
  .details_image {
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: var(--#{$prefix}border-radius);

    img {
      width: 100%;
      display: block;
    }
  }

  .details_item_title {
    font-size: 42px;
    line-height: 50px;
    margin-bottom: 20px;
  }

  .details_content {
    h2 {
      margin-top: 3rem;
      margin-bottom: 1rem;
    }
    h3 {
      margin-top: 3rem;
      margin-bottom: 1rem;
    }

    p {
      text-align: justify;

      font-size: 1.2rem;
      line-height: 1.3;
      // margin-bottom: 30px;

      span {
        font-weight: bold;
      }
    }

    p + p {
      margin-bottom: 1rem;
    }
    // p ~ ul {
    //   margin-bottom: 0;
    // }

    p + ul {
      // margin-bottom: 0;
    }

    ul {
      list-style-type: disc;

      margin-left: 2rem;
      margin-bottom: 1rem;

      font-size: 1.2rem;
      line-height: 1.3;

      li {
        // margin-bottom: 30px;
      }
    }
  }

  .video_wrapper {
    margin-bottom: 40px;
  }

  .details_item_title {
    font-size: 36px;
    line-height: 44px;
  }
}
